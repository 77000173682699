<template>
  <div>
    <portal to="breadcrumb">
      <Breadcrumb>
        <b-breadcrumb-item active>
          Members
        </b-breadcrumb-item>
      </Breadcrumb>
    </portal>
    <div class="material-card-content">
      <inviteMembers
        v-if="!$adDomain && validateIfHasPermission([
          permissions.INVITE_USERS.value.dbValue
        ])"
      />
      <invitedMembers
        v-if="validateIfHasPermission([
          permissions.INVITE_USERS.value.dbValue
        ])"
      />
      <members />
    </div>
  </div>
</template>

<script>
import members from '@/components/users/Members.vue';
import inviteMembers from '@/components/users/InviteMembers.vue';
import invitedMembers from '@/components/users/InvitedMembers.vue';
import UserMixin from '@/mixins/users';
import RoleMixin from '@/mixins/roles';
import vtulEnums from '../../../cross/index';

const { permissions } = vtulEnums.enum.roles;

export default {
  name: 'CompanyUsers',
  metaInfo: {
    title: 'Team',
  },
  components: {
    members,
    inviteMembers,
    invitedMembers,
  },
  mixins: [UserMixin, RoleMixin],
  data() {
    return {
      permissions,
    };
  },
};
</script>

<style scope>
  .invite-email {
    display: flex;
    flex-flow: row;
  }
  .invite-email-text {
    margin-left: 1rem;
    line-height: 34px;
  }
  .has-margin-top{
    margin-top:0.7rem;
  }
</style>
