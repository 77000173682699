export default {
  name: 'LoadingMixin',
  data() {
    return {
      loadingComponent: {},
    };
  },
  destroyed() {
    if (this.loadingComponent && this.loadingComponent.isActive) {
      this.closeLoading();
    }
  },
  methods: {
    openLoading() {
      if (this.loadingComponent && !this.loadingComponent.isActive) {
        this.loadingComponent = this.$buefy.loading.open({
          container: null,
        });
      }
    },
    closeLoading() {
      this.loadingComponent.close();
    },
  },
};
