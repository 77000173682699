<template>
  <card
    external-card="card material-card-overflow "
    internal-card="material-card-content"
  >
    <div
      slot="body"
    >
      <div>
        <p class="dashboardSubtitle">
          <span
            slot="header"
            class="subtitle"
          >
            Boardgent members
          </span>
        </p>
        <div class="has-margin-top">
          <b-field
            style="flex: 1"
          >
            <b-input
              v-model="search"
              v-focus
              name="search"
              icon="magnify"
              type="search"
              placeholder="Search teammates..."
              style="width: -webkit-fill-available;"
              autocomplete="off"
              @input="searching"
            />
          </b-field>
          <b-table
            :data="filteredData"
            :mobile-cards="false"
            default-sort="name"
            class="table-members"
          >
            <b-table-column
              v-for="(column, index) in columns"
              :key="index"
              :label="column.title"
              :visible="column.visible"
              :field="column.field"
              style="white-space: nowrap"
            >
              <template v-slot:header="">
                <div
                  v-if="column.field === 'permissions'"
                  @click="crossHelper.openExternalLink('https://help.boardgent.com/articles/4616813-description-of-roles', true)"
                >
                  {{ column.title }}
                </div>
                <div v-else>
                  {{ column.title }}
                </div>
              </template>
              <template
                v-slot="props"
              >
                <span
                  v-if="column.field === 'email'"
                  class="invite-email"
                >
                  <UserImage
                    :key="props.row.email ? props.row.email : ''"
                    :email="props.row.email ? props.row.email : ''"
                    :size="25"
                  />
                  <span class="invite-email-text">
                    {{ props.row.email ? props.row.email : '' }}
                  </span>
                </span>
                <span v-if="column.field === 'name'">
                  {{ props.row.name ? getFullName(props.row) : 'Name not registered' }}
                </span>
                <div v-else-if="column.field === 'permissions'">
                  <div
                    v-if="props.row.id === user.id || props.row.isOwner
                      || !validateIfHasPermission([
                        permissions.MODIFY_ROLES.value.dbValue,
                      ])"
                  >
                    <p>
                      {{ props.row.isOwner ? 'Owner' : roles.find(
                        (r) => props.row.membership.roleId === r.id) ? roles.find(
                        (r) => props.row.membership.roleId === r.id).name : '' }}
                    </p>
                  </div>
                  <div
                    v-else
                  >
                    <b-dropdown
                      aria-role="list"
                      scrollable
                    >
                      <template #trigger="{ active }">
                        <b-button
                          :label="roles.find(
                            (r) => props.row.membership.roleId === r.id) ? roles.find(
                            (r) => props.row.membership.roleId === r.id).name : ''"
                          type="is-primary"
                          :icon-right="active ? 'menu-up' : 'menu-down'"
                        />
                      </template>
                      <div
                        v-for="role in roles"
                        :key="role.id"
                      >
                        <b-dropdown-item
                          aria-role="listitem"
                          @click="changeRole(role.id, props.row.id)"
                        >
                          {{ role.name }}
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </div>
                </div>
                <span
                  v-if="column.field === 'twoFactorEnabled'"
                  style="display: flex"
                >
                  {{ props.row.twoFactorEnabled ? 'Enabled' : 'Disabled' }}
                </span>
                <span
                  v-if="column.field === 'action' && validateIfHasPermission([
                    permissions.MANAGE_USERS.value.dbValue,
                  ])"
                >
                  <b-button
                    v-if="!props.row.isOwner && props.row.twoFactorEnabled
                      && ownerHas2FA"
                    type="is-text"
                    @click="openConfirmationModal(props.row)"
                  >
                    <b-tooltip
                      label="Disable 2FA"
                      size="is-medium"
                      type="is-dark"
                    >
                      <b-icon
                        icon="two-factor-authentication"
                        custom-class="red-vtul-icon"
                        type="is-danger"
                      />
                    </b-tooltip>
                  </b-button>
                  <b-button
                    v-if="!props.row.isOwner"
                    type="is-text"
                    @click="deleteUserFromCompany({
                      email: props.row.email ? props.row.email : '',
                      name: props.row.name,
                      lastName: props.row.lastName,
                      id: props.row.id,
                    })"
                  >
                    <b-tooltip
                      :label="props.row.id !== user.id ? 'Remove user' : 'Leave the company'"
                      size="is-medium"
                      type="is-dark"
                    >
                      <b-icon
                        style="margin-bottom:4px"
                        :icon="props.row.id !== user.id ? 'delete' : 'logout'"
                        custom-class="red-vtul-icon"
                        type="is-danger"
                      />
                    </b-tooltip>
                  </b-button>
                </span>
              </template>
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon
                      icon="file"
                      size="is-large"
                    />
                  </p>
                  <p>Try another search</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import Fuse from 'fuse.js';
import { mapActions, mapGetters } from 'vuex';
import { paramsForServer } from 'feathers-hooks-common';
import card from '@/components/cross/Card.vue';
import userImage from '@/components/cross/UserImage.vue';
import confirmation from '@/components/cross/ModalConfirmation.vue';
import toastMessage from '@/helpers/toastMessage';
import crossHelper from '@/helpers/cross';
import LodingMixin from '@/mixins/loading';
import ErrorMixin from '@/mixins/error';
import UserMixin from '@/mixins/users';
import RoleMixin from '@/mixins/roles';
import snackBarMessage from '@/helpers/snackBarMessage';
import vtulEnums from '../../../../cross/index';

const { operationalUiErrors } = vtulEnums.enum.ui;
const { alertBox } = vtulEnums.enum.alert;
const { permissions } = vtulEnums.enum.roles;

const fuseOptions = {
  shouldSort: true,
  tokenize: true,
  matchAllTokens: true,
  maxPatternLength: 0,
  minMatchCharLength: 1,
  keys: ['name', 'email'],
};
export default {
  name: 'Members',
  components: {
    card,
    UserImage: userImage,
  },
  mixins: [LodingMixin, ErrorMixin, UserMixin, RoleMixin],
  data() {
    return {
      search: '',
      filteredData: [],
      usersRoles: [],
      fuse: null,
      columns: [
        {
          id: 'email', title: 'Email', field: 'email',
        },
        {
          id: 'name', title: 'Name', field: 'name',
        },
        {
          id: 'permissions', title: 'Role', field: 'permissions',
        },
        {
          id: 'twoFactorEnabled', title: 'Two-Factor authentication', field: 'twoFactorEnabled',
        },
        {
          id: 'action', title: 'Actions', field: 'action',
        },
      ],
      crossHelper,
      permissions,
    };
  },
  computed: {
    ...mapGetters('user', { findUsersInStore: 'find' }),
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    ...mapGetters('roles', { findRolesInStore: 'find' }),
    ...mapGetters('memberships', { findMembershipsInStore: 'find' }),
    roles() {
      const roles = this.findRolesInStore({
        query: {
          companyId: this.currentCompany.id,
          $select: ['name', 'id', 'companyId'],
        },
      });
      return roles.data;
    },
    memberships() {
      const memberships = this.findMembershipsInStore({
        query: {
          companyId: this.currentCompany.id,
          $select: ['isOwner', 'userId', 'roleId'],
        },
      });
      return memberships.data;
    },
    companyUsers() {
      if (this.currentCompany) {
        const companyMemberships = this.memberships;

        if (companyMemberships.length < 1) {
          return [];
        }

        const users = this.findUsersInStore({
          query: {
            id: { $in: companyMemberships.map((x) => x.userId) },
            $sort: { createdAt: -1 },
          },
        });
        return users.data;
      }
      return [];
    },
    usersWithRoles() {
      const usersList = this.companyUsers;
      if (this.companyUsers.length > 0 && this.memberships.length > 0) {
        this.companyUsers.forEach((user, index) => {
          const membership = this.memberships.find((m) => m.userId === user.id);
          usersList[index].isOwner = membership.isOwner;
          usersList[index].membership = membership;
        });
      }
      this.updateFuseData(usersList);
      return usersList;
    },
    ownerHas2FA() {
      const currentUser = this.$store.getters['auth/user'];
      const usersList = this.companyUsers;
      const owner = usersList.filter((user) => currentUser.id === user.id
        && user.twoFactorEnabled
        && (user.isOwner || user.isSupportAccount));
      return owner.length > 0;
    },
  },
  watch: {
    usersWithRoles() {
      this.searching();
    },
    memberships() {
      this.searching();
    },
  },
  async created() {
    this.searching(); // Update from local storage
    this.fetchRolesFromApi();
    this.fetchMembershipsFromApi();
  },
  methods: {
    ...mapActions('user', { findUsers: 'find', updateUser: 'patch' }),
    ...mapActions('memberships', { findMemberships: 'find', removeUserFromCompany: 'remove', changeMembershipRole: 'patch' }),
    ...mapActions('roles', { findRoles: 'find' }),
    ...mapActions('admin-log', { createAdminLog: 'create' }),
    searching() {
      const input = this.search;
      if (input && this.fuse) {
        this.filteredData = this.fuse.search(input);
      } else {
        this.filteredData = this.usersWithRoles;
      }
      this.filteredData = this.filteredData.filter((user) => !user.isSupportAccount);
    },
    updateFuseData(data) {
      this.fuse = new Fuse(data, fuseOptions);
    },
    async fetchRolesFromApi(skip) {
      if (!this.currentCompany) return;
      let skipRoles = skip;
      if (!skipRoles) skipRoles = 0;
      const { total, data } = await this.findRoles({
        query: {
          $sort: { createdAt: -1 },
          $limit: 50,
          $skip: skipRoles,
          companyId: this.currentCompany.id,
        },
      });
      skipRoles += data.length;
      if (skipRoles < total) {
        await this.fetchRolesFromApi(skipRoles);
      }
    },
    async fetchMembershipsFromApi(skip) {
      if (!this.currentCompany) return;
      let skipMemberships = skip;
      if (!skipMemberships) skipMemberships = 0;
      const { total, data } = await this.findMemberships({
        query: {
          $sort: { createdAt: -1 },
          $limit: 50,
          $skip: skipMemberships,
          companyId: this.currentCompany.id,
        },
      });

      this.findUsers({
        query: {
          id: { $in: data.map((x) => x.userId) },
          $sort: { createdAt: -1 },
          $limit: 50,
        },
      });

      skipMemberships += data.length;
      if (skipMemberships < total) {
        await this.fetchMembershipsFromApi(skipMemberships);
      }
    },
    deleteUserFromCompany(user) {
      const name = user.name ? this.getFullName(user) : user.email;
      this.$buefy.modal.open({
        parent: this,
        component: confirmation,
        hasModalCard: true,
        props: {
          title: 'Remove user from company ',
          message: `Are you sure to want remove the user <b>${name}</b> from the company?`,
          confirmText: 'Remove',
          cancelText: 'Cancel',
          onConfirm: async () => {
            this.openLoading();
            let memberships;
            try {
              const { data } = await this.findMemberships({
                query: {
                  $limit: 1,
                  userId: user.id,
                  companyId: this.currentCompany.id,
                },
              });
              memberships = data;
            } catch (err) {
              this.throwVtulError(operationalUiErrors.ERROR_REMOVE_USER,
                alertBox.TOAST.value, err);
            }

            if (memberships && memberships.length > 0) {
              const userMembership = memberships[0];
              let deletedUser;
              try {
                deletedUser = await this.removeUserFromCompany([
                  userMembership.id, {
                    query: {
                      companyId: this.currentCompany.id,
                    },
                  }]);
              } catch (err) {
                this.throwVtulError(operationalUiErrors.ERROR_REMOVE_USER,
                  alertBox.TOAST.value, err);
              } finally {
                if (deletedUser && deletedUser.id) toastMessage.showSuccess('The user was removed.');
                const userAdmin = this.$store.getters['auth/user'];
                const log = {
                  user: userAdmin.email,
                  action: `User ${name} deleted.`,
                  companyId: this.currentCompany.id,
                };
                await this.createAdminLog(log);
              }
            }
            this.closeLoading();
          },
        },
      });
    },
    async addLog(userName) {
      const user = await this.$store.getters['auth/user'];
      const secundaryUser = await this.findUsers({ query: { id: userName } });
      const log = {
        user: user.email,
        action: `Changed roles for ${secundaryUser.data[0].name}`,
        companyId: this.currentCompany.id,
      };
      await this.createAdminLog(log);
    },
    async changeRole(roleId, userId) {
      try {
        const { data } = await this.findMemberships({
          query: {
            $limit: 1,
            userId,
            companyId: this.currentCompany.id,
          },
        });
        await this.changeMembershipRole([data[0].id, {
          roleId,
          companyId: this.currentCompany.id,
        }]);
        await this.addLog(userId);
      } catch (err) {
        snackBarMessage.showError(err);
      }
    },
    getFullName(user) {
      const { name, lastName } = user;
      if (name && lastName) return `${name} ${lastName}`;
      return user.name;
    },
    openConfirmationModal(employee) {
      const userReference = employee.name && employee.lastName ? `${employee.name} ${employee.lastName}` : `${employee.email}`;
      this.$buefy.modal.open({
        parent: this,
        component: confirmation,
        hasModalCard: true,
        props: {
          title: 'Remove two-factor authentication',
          message: `Do you want to delete ${userReference}'s two-factor authentication?`,
          confirmText: 'Accept',
          onConfirm: () => this.delete2FAFromUser(employee.id),
        },
      });
    },
    async delete2FAFromUser(employeeId) {
      try {
        await this.updateUser([employeeId, {
          twoFactorEnabled: false,
          twoFactorRecoveryKey: null,
          twoFactorSecret: null,
        }, paramsForServer({
          requestedInformation: {
            remove2FAFromUser: true,
            companyId: this.currentCompany.id,
          },
        }),
        ]);
        toastMessage.showSuccess('The two-factor authentication has been removed');
      } catch (error) {
        snackBarMessage.showError(error);
      }
    },
  },
};
</script>
<style>

  .table-members > .table-wrapper {
    overflow-x: auto;
  }

 .red-vtul-icon{
   color:#632d8e;
 }
</style>
