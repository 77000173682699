<template>
  <card
    v-if="invitedUsers.length > 0"
    external-card="card material-card mb-5"
    internal-card="material-card-content"
  >
    <div
      slot="body"
    >
      <p class="dashboardSubtitle">
        <span
          slot="header"
          class="subtitle"
        >
          Invited Users
        </span>
      </p>
      <div class="has-margin-top">
        <b-table
          :data="invitedUsers"
          :mobile-cards="false"
          default-sort="name"
        >
          <b-table-column
            v-for="(column, index) in columns"
            :key="index"
            :label="column.title"
            :visible="column.visible"
            :field="column.field"
            style="white-space: nowrap"
          >
            <template v-slot="props">
              <span
                v-if="column.field === 'email'"
                class="invite-email"
              >
                <UserImage
                  :email="props.row.email "
                  :size="25"
                />
                <span class="invite-email-text">{{ props.row.email }}</span>
              </span>
              <span
                v-if="column.field === 'action'"
                class="force-right"
              >
                <button
                  class="button button-invite is-outlined is-primary"
                  @click="resendInvitation(props.row.id, props.row.email)"
                >
                  <b-icon
                    icon="email-send-outline"
                  />
                  <p>Re-send</p>
                </button>
                <button
                  class="button button-invite is-primary"
                  icon-right="delete"
                  @click="cancelInvitation(props.row.id)"
                >
                  <b-icon
                    icon="delete"
                  />
                  <p>Cancel</p>
                </button>
              </span>
            </template>
          </b-table-column>

          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="file"
                    size="is-large"
                  />
                </p>
                <p>There are no invited users.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import toastMessage from '@/helpers/toastMessage';
import userImage from '@/components/cross/UserImage.vue';
import card from '@/components/cross/Card.vue';
import ErrorMixin from '@/mixins/error';
import vtulEnums from '../../../../cross/index';

const { operationalUiErrors } = vtulEnums.enum.ui;
const { alertBox } = vtulEnums.enum.alert;

export default {
  name: 'InvitedMembers',
  components: {
    card,
    UserImage: userImage,
  },
  mixins: [ErrorMixin],
  data() {
    return {
      search: '',
      filteredData: [],
      usersList: [],
      searchInput: '',
      columns: [
        {
          id: 'email', title: 'Email', field: 'email',
        },
        {
          id: 'action', title: '', field: 'action',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    ...mapGetters('invitations', { findInvitedUsersInStore: 'find' }),
    invitedUsers() {
      const users = this.findInvitedUsersInStore({
        query: {
          companyId: this.currentCompany.id,
          $sort: { createdAt: -1 },
        },
      });
      return users && users.data ? users.data : [];
    },
  },
  created() {
    this.fetchInvitedUsersFromApi();
  },
  methods: {
    ...mapActions('company-management', { resendInvite: 'create' }),
    ...mapActions('invitations', { findInvitedUsers: 'find' }),
    ...mapActions('invitations', { removeInvitedUser: 'remove' }),
    async fetchInvitedUsersFromApi(skip) {
      let skipUsers = skip;
      if (!skipUsers) skipUsers = 0;
      try {
        const { total, data } = await this.findInvitedUsers({
          query: {
            companyId: this.currentCompany.id,
            $sort: { createdAt: -1 },
            $limit: 50,
            $skip: skipUsers,
          },
        });
        skipUsers += data.length;
        if (skipUsers < total) {
          await this.fetchInvitedUsersFromApi(skipUsers);
        }
      } catch (err) {
        this.throwVtulError(operationalUiErrors.ERROR_FIND_INVITED_USERS,
          alertBox.TOAST.value, err);
      }
    },
    cancelInvitation(id) {
      this.removeInvitedUser(id).then(() => {
        toastMessage.showSuccess('The invitation was canceled.');
      }).catch((err) => {
        this.throwVtulError(operationalUiErrors.ERROR_CANCEL_INVITATION,
          alertBox.TOAST.value, err);
      });
    },
    resendInvitation(id, email) {
      const currentUser = this.$store.getters['auth/user'];
      const params = {
        emailFrom: currentUser.email,
        nameFrom: currentUser.name ? currentUser.name : currentUser.email,
        companyName: this.currentCompany.name,
        inviationId: id,
        action: 'resendInvitation',
      };
      this.resendInvite(params).then(() => {
        toastMessage.showSuccess(`The invitation has been send again to ${email}.`);
      }).catch((err) => {
        this.throwVtulError(operationalUiErrors.ERROR_RESENT_INVITATION,
          alertBox.TOAST.value, err, true);
      });
    },
  },
};
</script>
<style scope>
 .button-invite{
   margin: 0 1rem;
 }
</style>
