<template>
  <card
    external-card="card material-card mb-5"
    internal-card="material-card-content"
  >
    <div
      slot="body"
    >
      <p class="dashboardSubtitle">
        <span
          slot="header"
          class="subtitle"
        >
          Invite members via email
        </span>
      </p>
      <p class="has-margin-top">
        <b-message
          type="is-success"
          style="margin-bottom: 8px;"
        >
          <p class="center-vertically-action-button">
            You can invite unlimited teammates to Boardgent
          </p>
        </b-message>
      </p>
      <form
        action=""
        @submit.prevent="validateBeforeSubmit"
      >
        <div class="is-flex has-margin-top">
          <div class="is-flex-grow-1 mr-3">
            <b-input
              v-model="invitationEmails"
              v-validate="'required|verify_emails_list'"
              data-vv-validate-on="submit"
              name="email"
              placeholder="email1@example.com, email2@example.com"
              style=""
            />
            <span
              v-show="errors.has('email')"
              class="help is-danger"
            >
              {{ errors.first('email') }}
            </span>
          </div>
          <div class=" force-right">
            <button
              :class="{'is-loading': isLoading }"
              type="submit"
              class="button is-primary"
            >
              Send invites
            </button>
          </div>
        </div>
      </form>
    </div>
  </card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import toastMessage from '@/helpers/toastMessage';
import card from '@/components/cross/Card.vue';

import ErrorMixin from '@/mixins/error';
import analyticsMixin from '@/mixins/analytics';

import vtulEnums from '../../../../cross/index';

const { operationalUiErrors } = vtulEnums.enum.ui;
const { alertBox } = vtulEnums.enum.alert;

export default {
  name: 'InviteMembers',
  components: {
    card,
  },
  mixins: [ErrorMixin, analyticsMixin],
  data() {
    return {
      isLoading: false,
      invitationEmails: undefined,
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    ...mapActions('company-management', { sendCompanyInvite: 'create' }),
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        const currentUser = this.$store.getters['auth/user'];
        const params = {
          emailTo: this.invitationEmails,
          emailFrom: currentUser.email,
          nameFrom: currentUser.name ? currentUser.name : currentUser.email,
          companyName: this.currentCompany.name,
          companyId: this.currentCompany.id,
          action: 'companyInvite',
        };
        if (result) {
          this.sendCompanyInvitation(params);
        }
      }).catch((error) => { throw new Error(error); });
    },
    sendCompanyInvitation(params) {
      this.trackEvent(!this.currentCompany.isTestData && !this.user.isTestData,
        'track', 'Invite User', params);
      this.error = undefined;
      this.isLoading = true;
      this.sendCompanyInvite(params).then((invitationResult) => {
        if (invitationResult.errors && invitationResult.errors.length > 0) {
          invitationResult.errors.forEach((error) => {
            toastMessage.showError(error);
          });
        } else {
          toastMessage.showSuccess('Email invitations sent.');
        }
      }).catch((err) => {
        this.throwVtulError(operationalUiErrors.ERROR_SEND_INVITATIONS,
          alertBox.TOAST.value, err, true);
      }).finally(() => {
        this.invitationEmails = '';
        this.isLoading = false;
      });
    },
  },
};
</script>
